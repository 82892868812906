import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import onboardingCardData from "../../utils/onboardingCardData.json";
import OnBoardingComponent from "./onBoardingComponent";
import useWindowDimension from "../../components/hooks/useWindowDimension";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleOnboarding, handleOtpFlag, handlePlanSelect, verifyOTP } from "../../store/features/auth/authSlice";
import { AppDispatch } from "../../interface/userInterface";
import { showToast } from "../../store/features/toast/toastSlice";
import Loader from "../../components/loader/Loader";

const OnBoardingContainer = () => {
  const dimensions = useWindowDimension();
  const navigate = useNavigate();
  const location = useLocation()
  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()
  const token = localStorage.getItem('accessToken')
  const authData = useSelector((state: any) => state?.auth?.loginResponse)
  const [about, setAbout] = useState(false);
  const [work, setWork] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const isToken = useRef(false)
  const [tokenOTP, setTokenOTP] = useState(false);
  const [plan, setPlan] = useState<string | undefined>(undefined);
  const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const OnboardingCard = React.lazy(() => import('../../components/cards/onboardingCard/onboardingCard'));
  const loginResponse = useSelector((state: any) => state?.auth?.signUpResponce)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (params?.id) {
      const queryParams: any = {
        userID: params?.id
      }
      dispatch(verifyOTP(queryParams)).then((data: any) => {
        localStorage.setItem("accessToken", data?.payload?.data?.accessToken)
        localStorage.setItem("refreshToken", data?.payload?.data?.refreshToken)
        localStorage.setItem("userId", data?.payload?.data?.userId)
        localStorage.setItem("userRole", data?.payload?.data?.userRole)
        isToken.current = true
        setTimeout(() => {
          const message = "Your account is verified now.";
          dispatch(
            showToast({
              message,
              type: "success",
              time: 10000
            })
          );
        }, 3000);
      })
    }
  }, [])

  useEffect(() => {
    const token: any = localStorage.getItem("accessToken");
    const userID = localStorage.getItem("userId")
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_URL}auth/onboarding-verify?userID=${userID}`);


    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.status === "onboarding verified") {
        setLoading(true)
        dispatch(handleOtpFlag(true))
        setTimeout(() => {
          setLoading(false)
          navigate("/dashboard", { state: data?.status })
        }, 6000)
      }
    };

    eventSource.onerror = () => {
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  /**
 * Redirects the user based on authentication and onboarding status.
 * If token doesn't exist or user role is not "user", redirects to the login page.
 * If token exists and the user is onboarded, redirects to the dashboard.
 */

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!params?.id && !(token && authData?.data?.role === "user")) {
      navigate("/login")
    } else if (!params?.id && token && (authData?.data?.isOnBoarded || subscribeResponse?.data?.isOnBoarded || loginResponse?.data?.isOnBoarded)) {
      navigate("/dashboard")
    }
  }, [tokenOTP, isToken?.current])

  const handlePlan = (data: "FREE" | "MONTHLY" | "ANNUALLY"): void => {
    const planData: string = data === "FREE" ? "etclite" : data === "MONTHLY" ? "etcmonthly" : "etcannual";
    dispatch(handlePlanSelect(planData))
    setPlan(planData);
  };

  if (loading) return <div className="fade offcanvas-backdrop show">
    <Loader />
  </div>;
  return (
    <>
      <Helmet>
        <title>Onboarding | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Create your account: select your preferred Triptix package (or start a free trial) & fill out your details to sign up." />
      </Helmet>
      <OnBoardingComponent
        onboardingCardData={onboardingCardData.onboardingCardData}
        dimensions={dimensions}
        about={about}
        setAbout={setAbout}
        work={work}
        setWork={setWork}
        purchase={purchase}
        setPurchase={setPurchase}
        OnboardingCard={OnboardingCard}
        setPlan={setPlan}
        handlePlan={handlePlan}
        plan={plan}
        fullName={location?.state?.fullName}
      />
    </>
  );
};

export default OnBoardingContainer;
