import React   from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { publicRoutes, authProtectedRoutes, adminPublicRoutes, adminAuthProtectedRoutes } from "./routes";
import Layout from "./layout";
import ToastComponent from "./components/toastComponent/toastComponent";
import AdminLayout from "./adminLayout";
import { useSelector } from "react-redux";



function App() {
  const authData = useSelector((state: any) => state?.auth?.loginResponse)
  const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const token =  localStorage.getItem('accessToken')
  const otpData = useSelector((state: any) =>state?.auth?.signUpResponce)
  const userInfo = useSelector((state: any) => state?.auth?.userInfo)
  const isUserInfoEmpty = Object.keys(userInfo || {}).length === 0;
  return (
    <React.Fragment>
      <ToastComponent />
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        { authProtectedRoutes.map((route, idx) => (
          <Route key={idx} element={<Layout />}>
            <Route path={route.path}  element={ (authData?.data?.role === "user"  || (userInfo?.role === "user" || isUserInfoEmpty) || otpData?.data?.role === "user") && ((token || authData?.data?.accessToken) && (authData?.data?.isOnBoarded ||subscribeResponse?.data?.isOnBoarded  ||  (userInfo?.isOnBoarded === true || isUserInfoEmpty))) ? route.element :  <Navigate to="/" />} />
          </Route>
        ))}
        {adminPublicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        {adminAuthProtectedRoutes.map((route, idx) => (
          <>
          {
          <Route key={idx} element={<AdminLayout />}>
            <Route path={route.path} element={ authData?.data?.role === "admin" && (token || authData?.data?.accessToken) ? route.element :  <Navigate to="/admin/login" />} />
          </Route>
          }
          </>
        ))}
      </Routes>
    </React.Fragment>
  );
}
export default App;
