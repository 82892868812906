import React, { useEffect, useState } from 'react'
import SettingComponent from './settingComponent'
import { Helmet } from 'react-helmet'
import { showToast } from '../../store/features/toast/toastSlice';
import { changePassword ,resetPassword} from '../../store/features/setting/settingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../interface/userInterface';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Constant from '../../utils/constant';
import purchaseCardData from "../../utils/purchaseCardData.json";
import { updateSubscriptionPlan } from '../../store/features/auth/authSlice';
import { handleIsImported } from '../../store/features/auth/authSlice';

const SettingContainer:React.FC = (props: any) => {
  const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>()
	const navigate = useNavigate()
	const [loading,setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false);
	const [plan, setPlan] = useState<string | undefined>(undefined)
 	const [confirmShowPassword, setConfirmShowPassword] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [planModel,setPlanModal] = useState<number | undefined>();

	const socialLogin = useSelector((state:any)=> state?.auth?.social)
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
  const userInfo = useSelector((state: any) => state?.auth?.userInfo)
	const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const planSub = useSelector((state:any)=> state.auth.subscriptionPlan)
	const resetPasswordResponse = useSelector((state: any) => state?.setting?.resetPasswordResponse)
	const subscriptionPlan = useSelector((state: any) => state?.auth?.subscriptionPlan)
	/**
 * Set plan modal based on authentication or subscription response.
 */

	useEffect(()=>{
		if (authResponse?.data?.plan) {
			  const planCondition = 	authResponse?.data?.plan === "etclite" ? 1 :authResponse?.data?.plan === "etcmonthly" ? 2 : 3 
				setPlanModal(planCondition)
		}else if (userInfo?.plan) {
			const planCondition = 	userInfo?.plan === "etclite" ? 1 :userInfo?.plan === "etcmonthly" ? 2 : 3 
			setPlanModal(planCondition)
		}
		else {
			if (subscribeResponse?.data?.plan) {
				const planCondition = 	subscribeResponse?.data?.plan === "etclite"  ? 1 :subscribeResponse?.data?.plan === "etcmonthly"  ? 2 : 3 
				setPlanModal(planCondition)
			}
		}

	},[authResponse,subscribeResponse])

/**
 * Handles the selection of subscription plan.
 * @param data - The selected plan (FREE, MONTHLY, or ANNUALLY).
 */
	const handlePlan = (data: "FREE" | "MONTHLY" | "ANNUALLY"): void => {
    const planData: string = data === "FREE" ? "etclite" : data === "MONTHLY" ? "etcmonthly" : "etcannual";
    setPlan(planData);
		setLoading(true)
    const planDetails={
      userId:authResponse?.data?.userId || authResponse?.data?._id,
      plan:planData
    }
    // dispatch(updateSubscriptionPlan(planDetails)).then((resultAction)=>{
		// 	if (updateSubscriptionPlan.fulfilled.match(resultAction)) {
		// 		dispatch(
		// 			showToast({
		// 				message: (resultAction as any)?.payload?.message || "",
		// 				type: "success",
		// 			})
		// 		);
		// 		setLoading(false)
		// 		setModalShow(false)
		// 	}else if (updateSubscriptionPlan.rejected.match(resultAction)) {
		// 		dispatch(
		// 			showToast({
		// 				message: (resultAction as any)?.payload?.message,
		// 				type: "error",
		// 			})
		// 		);
		// 		setLoading(false)
		// 		setModalShow(false)
		// 	}
    // });
  };


	const onFormSubmit = async (value: any) => {
		setLoading(true)  
		if (socialLogin ) {
			const password ={
				password:value.password,
				// conform:value.password
			}
			dispatch(resetPassword(password)).then((resultAction)=>{
				if (resetPassword.fulfilled.match(resultAction)) {
					dispatch(
						showToast({
							message: (resultAction as any)?.payload?.message,
							type: "success",
						})
					);
					navigate("/login")
					setLoading(false);
					dispatch(handleIsImported(false))
				}
				else{
					dispatch(
						showToast({
							message: (resultAction as any)?.error?.message,
							type: "error",
						})
					);
					setLoading(false);
				}
			})
		}else {
			dispatch(changePassword(value)).then((resultAction)=>{
				if (changePassword.fulfilled.match(resultAction)) {
					dispatch(
						showToast({
							message: (resultAction as any)?.payload?.message,
							type: "success",
						})
					);
					navigate("/login")
					setLoading(false);
				}
				else{
					dispatch(
						showToast({
							message: (resultAction as any)?.payload?.message,
							type: "error",
						})
					);
					setLoading(false);
				}
			})
		}
  }

  const resetForm = {
		password: {
			name: "password",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_ENTER_PASSWORD"),
				},
				validate: {
					hasLowerCase_UpperCase: (value: string) =>
						value &&
						value.match(Constant.REGEX.LOWERCASE) &&
						value.match(Constant.REGEX.UPPERCASE) !== null,
					hasNumbers: (value: string) =>
						value && value.match(Constant.REGEX.NUMBER) !== null,
					hasSpecialChar: (value: string) =>
						value &&
						value.match(Constant.REGEX.SPECIAL_CHARECTERS) !== null,
					length: (value: string) =>
						(value && value.length >= 8 ) ||
						"",
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
		confirmpassword: {
			name: "confirmPassword",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

  const multiErrorFields = [
		{ length: t("MINIMUM_8_CHARACTERS") },
		{ hasSpecialChar: t("MINIMUM_1_SPECIAL") },
		{ hasNumbers: t("MINIMUM_1_NUMERIC") },
		{ hasLowerCase_UpperCase: t("MINIMUM_1_UPPERCASE_LOWERCASE") },
	];
  return (
    <>
       <Helmet>
        <title>Settings | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Manage your subscription, update your password, and more in our settings panel." />
      </Helmet>
			<SettingComponent
				signInForm={resetForm}
				onFormSubmit={onFormSubmit}
				multiErrorFields={multiErrorFields}
				loading={loading}
				showPassword={showPassword}
				setShowPassword={setShowPassword}
				confirmShowPassword={confirmShowPassword}
				setConfirmShowPassword={setConfirmShowPassword}
				socialLogin={socialLogin}
			  	purchaseCardData={purchaseCardData.purchaseCardData}
				setPlan={setPlan}
				modalShow={modalShow}
				setModalShow={setModalShow}
				planModel={planModel}
				handlePlan={handlePlan}
				authResponse={authResponse}				
			/>
    </>
  )
}

export default SettingContainer