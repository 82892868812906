import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { showToast } from "../../../store/features/toast/toastSlice";
import SignInComponent from "./signInComponent";
import Constant from "../../../utils/constant";
import { loginUser, removeOtp, socialLogin } from "../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../interface/authInterface";
import { storeEncryptedOTP } from "../../../components/crypto/cryptoUtils";

const SignInContainer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
	const [busy, setBusy] = useState(false);
	const [socialLoading, setSocialLoading] = useState(false);
	const [errorMsg,setErrorMsg] = useState<string | undefined>()
	const [assiocate,setAssiocate] = useState(false);
	const [errorMsgLogin,setErrorMsgLogin] = useState<string | undefined>("");
	const [socialLogingData,setSocialLoggingData] = useState<any>("")
	const [emailModal,setEmailModal] = useState<boolean>(false)
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
	const [loading, setLoading] = useState<boolean>(true);
  const token =  localStorage.getItem('accessToken')
  // const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const otpFlag = useSelector((state: any) => state?.auth?.otpFlag)
	//resetPasswordResponse
  const resetPasswordResponse = useSelector((state: any) => state?.setting?.resetPasswordResponse)

	useEffect(() => {
		 if (token && authResponse?.data?.role === "user" && authResponse?.data?.isOnBoarded) {
				if (authResponse?.data?.isImported  && !resetPasswordResponse?.data?.user?.isOnBoarded) {
    			navigate("/login")
					setLoading(false)

				}else {
					navigate("/dashboard")
				}
		} else if (token && authResponse?.data?.role === "user" && (authResponse?.data?.isOtpVerified || otpFlag) && !authResponse?.data?.isOnBoarded) {
			navigate("/onboarding")
		}
		else {
			setLoading(false)
		}
	}, [])


	
	const onFormSubmit = async (value: any) => {
		setBusy(true);
		const params = {
			...value,
			email:value?.email.toLowerCase(),
			userType: 'user'
		}
   /**
   * Dispatches a login action with the provided parameters and handles the result accordingly.
   * @param {object} params - The parameters for the login action.
   * @returns None
   */
		dispatch(loginUser(params)).then((resultAction) => {
			if (loginUser.fulfilled.match(resultAction)) {
					dispatch(
						showToast({
							message: (resultAction as any)?.payload?.message,
							type: "success",
							time:2000
						})
					);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
				window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
				window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.REMEMBER_ME, String(rememberMe));
				window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
				setBusy(false);
				if (rememberMe === true) {
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_EMAIL, value?.email);
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_PASSWORD, value?.password);
				} else {
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_EMAIL, "");
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.TRIPTIX_REMEMBER_ME_PASSWORD, "");
				}
				if (!resultAction?.payload?.data?.isOtpVerified) {
					const timeandDate = new Date()
					const expireTime = new Date(timeandDate.getTime() + 5 * 60 * 1000)
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
					window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
					// window.localStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
					storeEncryptedOTP(resultAction?.payload?.data?.otp,process.env.REACT_APP_SECRECT_KEY)
					dispatch(removeOtp(resultAction?.payload))
					navigate('/otp')
				} else if (!resultAction?.payload?.data?.isOnBoarded) {
					navigate("/onboarding");
				}else if (resultAction?.payload?.data?.isImported) {
					dispatch(
						showToast({
							message: t("PLEASE_ADD_YOUR_DETAILS_AND_PASSWORD"),
							type: "success",
						})
						);
					navigate("/setPassword");
				}  
				else {
					navigate('/dashboard')
				}
			} else if (loginUser.rejected.match(resultAction)) {
				setBusy(false);
				if(resultAction?.error?.message === "Sorry provided login credentials are invalid. Please try again!"){
					setErrorMsg(resultAction?.error?.message)	
				}else {
					dispatch(
						showToast({
							message: resultAction?.error?.message || "",
							type: "error",
						})
						);
					}
			}
		});
	};

	const signInForm = {
		email: {
			name: "email",
			
			validate: {
				required: {
					value: true,
					message: t("EMAIL_ADDRESS_IS_REQUIRED"),
				},
				pattern: {
					value: Constant.REGEX.EMAIL,
					message: t("ENTER_VALID_EMAIL_ADDRESS"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
		password: {
			name: "password",
			validate: {
				required: {
					value: true,
					message: t("PASSWORD_IS_REQUIRED"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

	const handleSocialLogin = (provider: string, data: any) => {
		const params: any = {
			loginType: provider,
			email: data.email?.toLowerCase(),
			fullName: data.name
		}
		if (provider === 'google') params.googleId = data.sub
		if (provider === 'facebook') params.facebookId = data.userID
		setSocialLoading(true);
		setSocialLoggingData(params)
		dispatch(socialLogin(params)).then((resultAction:any) => {
			if (socialLogin.fulfilled.match(resultAction)) {
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
				window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
				window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
				setSocialLoading(false);
				
				resultAction?.payload?.data?.isOnBoarded ? navigate("/dashboard") : navigate("/onboarding",{state:{ fullName:data?.name}});
			} else if (socialLogin.rejected.match(resultAction)) {
				setBusy(false);
				setSocialLoading(false);
        if ((resultAction as any)?.payload?.status === 406) {
           setAssiocate(true)        
					 setErrorMsgLogin((resultAction as any)?.payload?.message)	  
        }else {
					(resultAction as any)?.payload?.message === "Please provide valid email" && setEmailModal(true);
          (resultAction as any)?.payload?.message !== "Please provide valid email" && dispatch(
            showToast({
              message: (resultAction as any)?.payload?.message,
              type: "error",
            })
            );
          }
			}
		});
	}

	const rememberMeFunction = (value: boolean) => {
		setRememberMe(value)
	}

/**
 * Handles associate login for accounts created with normal or social login methods.
 */
	const associateLogin =()=>{
			const params = {
				...socialLogingData
			}
			if (socialLogingData?.googleId) {
				params.linkWithGoogle = true;
		} else {
				params.linkWithFacebook = true;
		}
		dispatch(socialLogin(params)).then((resultAction:any) => {
			if (socialLogin.fulfilled.match(resultAction)) {
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "success",
					})
				);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
				window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
				window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
				window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
				setSocialLoading(false);
				resultAction?.payload?.data?.isOnBoarded ? navigate("/dashboard") : navigate("/onboarding",{state:{ fullName:socialLogingData?.fullName}});
			} else if (socialLogin.rejected.match(resultAction)) {
				setBusy(false);
				setSocialLoading(false);
        if ((resultAction as any)?.payload?.status === 406) {
           setAssiocate(true)          
        }else {
          dispatch(
            showToast({
              message: (resultAction as any)?.payload?.message,
              type: "error",
            })
            );
          }
				setErrorMsgLogin((resultAction as any)?.payload?.message)
			}
		});
	}

	/**
 * Updates social login data with provided email.
 * @param e - Email input event object.
 */
	const emailProvide =(e:any)=>{
		const params = {
			...socialLogingData,
			email:e.email.toLowerCase(),
			isEmailPrompt:true 
		}
		setSocialLoggingData(params)
	  dispatch(socialLogin(params)).then((resultAction:any) => {
		if (socialLogin.fulfilled.match(resultAction)) {
			if (!resultAction?.payload?.data?.isOtpVerified) {
				const timeandDate = new Date()
				const expireTime = new Date(timeandDate.getTime() + 5 * 60 * 1000)
				window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
				window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
				storeEncryptedOTP(resultAction?.payload?.data?.otp,process.env.REACT_APP_SECRECT_KEY)
				dispatch(removeOtp(resultAction?.payload))
				navigate('/otp')
			}
			dispatch(
				showToast({
					message: (resultAction as any)?.payload?.message,
					type: "success",
				})
			);
			window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.ACCESS_TOKEN, resultAction?.payload?.data?.accessToken);
			window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.REFRESH_TOKEN, resultAction?.payload?.data?.refreshToken);
			window.localStorage.setItem("userRole", resultAction?.payload?.data?.role);
			window.localStorage.setItem(Constant?.LOCALSTORAGEKEYS?.USER_ID, resultAction?.payload?.data?.userId);
			setSocialLoading(false);
			setEmailModal(false);
			resultAction?.payload?.data?.isOnBoarded && navigate("/dashboard")
		} else if (socialLogin.rejected.match(resultAction)) {
			setBusy(false);
			setSocialLoading(false);
			setEmailModal(false);
			if ((resultAction as any)?.payload?.status === 406) {
				 setAssiocate(true)          
			}else {
				dispatch(
					showToast({
						message: (resultAction as any)?.payload?.message,
						type: "error",
					})
					);
				}
			setErrorMsgLogin((resultAction as any)?.payload?.message)
		}
	});
  }

if (loading) return null; 

  return (
    <>
      <Helmet>
        <title>Log In | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Log in to your Triptix account or sign up for free to start receiving fixtures, offers & news in your inbox. Get started." />
      </Helmet>
      <SignInComponent
				signInForm={signInForm}
				onFormSubmit={onFormSubmit}
				busy={busy}
				socialLoading={socialLoading}
				rememberMeFunction={rememberMeFunction}
				handleSocialLogin={handleSocialLogin}
				setShowPassword={setShowPassword}
				showPassword={showPassword}
				rememberMe={false}
				errorMsg={errorMsg}
			  	assiocate={assiocate}
				errorMsgLogin={errorMsgLogin}
				setAssiocate={setAssiocate}
				associateLogin={associateLogin}
				emailModal={emailModal} 
				emailProvide={emailProvide}
				setEmailModal={setEmailModal}  
		/>
    </>
  );
};

export default SignInContainer;
