import React, { useEffect, useRef } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useTranslation } from "react-i18next";
import "./fileUpload.css"

interface FileUploadProps {
  onDrop: (acceptedFiles: File[]) => void;
  maxSize?: number;
  accept?: any;
  triggerOpen?: React.MutableRefObject<(() => void) | null>;
  children?: React.ReactNode;
  onClick?: true,
  uploadedFiles?: File[] | null;
  profile?:boolean,
  handleSubmit?:()=>void;
  handleRedirect?:()=>void;
  handleFailedRedirect?:()=>void;
  isLoading?:boolean;
  status?:string;
  setRejectedFilesData?:any
  rejectedFilesData?:any;
  message?:string
}

function 
FileUpload({
  onDrop,
  maxSize,
  accept,
  triggerOpen,
  children,
  uploadedFiles,
  handleSubmit,
  handleRedirect,
  handleFailedRedirect,
  profile,
  status,
  isLoading,
  setRejectedFilesData,
  rejectedFilesData,
  message
}: FileUploadProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropzoneOptions: DropzoneOptions = {
    accept: accept,
    maxSize: maxSize,
    onDrop: onDrop,
    multiple: false,
  };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone(dropzoneOptions);
  const { t } = useTranslation();

  useEffect(() => {
    if (fileRejections) {
      setRejectedFilesData && setRejectedFilesData(fileRejections)
    }
  }, [fileRejections])

  useEffect(() => {
    if (inputRef.current) {
      if (triggerOpen) {
        triggerOpen.current = () => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        };
      }
    }
  }, [triggerOpen]);

    const handleRemoveFile = (file: any, isRejected: boolean = false) => {
      if (isRejected) {
        // Filter out the removed file from the rejected files array
        setRejectedFilesData("")
      } else {
        // Filter out the removed file from the accepted files array
        const updatedAcceptedFiles = acceptedFiles.filter(f => f !== file);
        onDrop(updatedAcceptedFiles);
      }
    };

  return (
    <>
    {
      profile?(
        <section className="container-style">
        <div {...getRootProps()}>
          <input {...getInputProps()} ref={inputRef} />
          {children}
        </div>
      </section>
      ):
      (
        <>
     {((!isLoading && status === "" ) || isLoading  ) && <h1 className='file-upload-container-header-title text-center'>{t("DATA_UPLOAD")}</h1>}
      <section className="container-style">
      { ((!isLoading && status === "") ) &&
      <>  <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} ref={inputRef} />
        {children}
        <img src="https://triptix.blob.core.windows.net/triptix-image/fileUpload.png" className="mb-4" alt="upload-img"/>
        <p className="main-text">{t("DRAG_&_DROP")} <span>{t("BROWSE")}</span> </p>
        <p className="sub-text">{t("SUPPORTED_FORMAT")}</p>
      </div>

      {/* // uploaded files */}
    <div className="show-file-name">
        {uploadedFiles && uploadedFiles.map((file: any,index:number) => (
          <React.Fragment key={index}>
          <p className="mb-0 mt-2 Upload-text">{t("UPLOADING")} - {index + 1} {t("FILE")}</p>
          <li key={file?.path} className="d-flex align-items-center justify-content-between">
            {file?.path}
            <span onClick={() => handleRemoveFile(file)} className="icon-cross "></span>
          </li>
          </React.Fragment>
        ))}
      </div>


      {/* // rejected options */}
    <div className="error-file">
        {rejectedFilesData?.length !== 0 && rejectedFilesData?.map((file: any,index:number) => (
          <>
          <p className="mb-0 mt-2 Upload-text">Uploading - {index + 1} file</p>
            <li key={file?.file?.path}>
              {file?.file?.path}
              <img src="https://triptix.blob.core.windows.net/triptix-image/corssIcon.png"  onClick={() => handleRemoveFile(file.file, true)} alt="remove-img"></img>
            </li>
          </>
        ))}
       {rejectedFilesData?.length !== 0 && <p className="error-text">{t("FILE_NOT_SUPPORTED")}</p>}
      </div>
      <button type="button" className="upload-button w-100" disabled={ uploadedFiles && uploadedFiles?.length > 0 ? false : true } onClick={()=> handleSubmit && handleSubmit()}>
        Upload File
      </button></> }

          {/* Success section  */}
          {status === "success" && <div className="success-section">
            <p className="success-text">{t("SUCCESS")}</p>
            <p className="document-text">{t("SUCCESS_DOCUMENTATION")}</p>
            <button onClick={()=> handleRedirect && handleRedirect()}>{t("VIEW_RESULTS")}</button>
        </div>}


           {/* failed section  */}
      { status === "failed" &&   <div className="error-section">
            <p className="success-text">{t("FAILED")}</p>
            <p className="document-text">{message}</p>
            <button onClick={()=> handleFailedRedirect &&handleFailedRedirect()}>{t("UPLOAD_AGAIN")}</button>
        </div>}

        {/* // animation  section */}
       {isLoading && <div className="animation-component">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>}

      </section>
      </>)}
    </>

  );
}

export default FileUpload;