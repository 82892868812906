import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate,useLocation ,useParams} from "react-router";
import ProfileComponent from "./profileComponent";
import { AppDispatch } from "../../../interface/userInterface";
import { createAccount, getUserDetails, handleIsImported, subscribe, updateProfile } from "../../../store/features/auth/authSlice";
import { showToast } from "../../../store/features/toast/toastSlice";
import { uploadFile } from "../../../helpers/helper.service";
import { getUserLeagueList } from "../../../store/features/userDashboard/userDashboardSlice";
import Constant from "../../../utils/constant";
import { resetPassword } from "../../../store/features/setting/settingSlice";

interface profileContainerProps {
  fullName: string;
  plan: string
}

const ProfileContainer: React.FC = () => {
  const location = useLocation()
  const params = useParams()

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [uploadProfile, setUploadProfile] = useState<any>(null)
  const [profileUpload, setProfileUpload] = useState<any>()
  console.log('profileUpload', profileUpload)
  const [leagueOption, setLeagueOption] = useState<any>()
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [isOpenLeague, setIsOpenLeague] = useState(false);
	const [loading, setLoading] = useState<boolean>(true);
  const leagueOptionRef = useRef<HTMLDivElement>(null);
  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
  const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const planSub = useSelector((state:any)=> state.auth.subscriptionPlan)
  const token =  localStorage.getItem('accessToken')
  const userId = localStorage.getItem('userId')
	const isImported = useSelector((state: any) => state?.auth?.isImported)

  const {fullName ,plan } = location?.state || {}

useEffect(()=>{
  if (params?.id) {
    const data = {
      sessionId:params?.id,
      userId:userId
    }
    dispatch(getUserDetails(data))
  }
},[])

  useEffect(() => {
		if (!isImported && token && authResponse?.data?.role === "user" && (authResponse?.data?.isOnBoarded ||subscribeResponse?.data?.isOnBoarded)) {
			navigate("/dashboard")
		} 
		else {
			setLoading(false)
		}
	}, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = uploadProfile && await uploadFile(uploadProfile);
        console.log('data', data)
        setProfileUpload(data)  
      } catch (error) {
        console.log("error",error)
      }
      
    };
    fetchData();
  }, [uploadProfile]);

  const onFormSubmit = async (value: any) => {
    setBusy(true);
    const { fullName, location } = value;
    const userId: string | null = localStorage.getItem("userId");
    if (isImported) {
      const password ={
        password:value.password
      }
      const createAccountParams = {
        userId: userId,
        fullName: fullName,
        location: location,
        teams: selectedOptions,
        profileImage:undefined
      }
      dispatch(updateProfile(createAccountParams)).then((resultAction)=>{
        if (resetPassword.fulfilled.match(resultAction)) {
					dispatch(
						showToast({
							message: t("YOUR_DETAILS_UPDATE"),
							type: "success",
						})
					);
        }
      })
      dispatch(resetPassword(password)).then((resultAction)=>{
				if (resetPassword.fulfilled.match(resultAction)) {
					navigate("/login")
					setBusy(false);
					dispatch(handleIsImported(false))
				}
				else{
					dispatch(
						showToast({
							message: (resultAction as any)?.error?.message,
							type: "error",
						})
					);
					setBusy(false);
				}
			})
    }
    else {
      let createAccountResponse :any;
      const SubscribeParams = {
        userId: userId,
        plan: plan || planSub,
        sessionId:params?.id
      };
      const createAccountParams = {
        userId: userId,
        fullName: fullName,
        location: location,
        teams: selectedOptions,
        profileImage: profileUpload || undefined
      }
      const subscribeResponse: any = await dispatch(subscribe(SubscribeParams));
      if (subscribeResponse?.payload?.message !== "Your onboarding is already completed.") {
        createAccountResponse = await dispatch(createAccount(createAccountParams));
      }
  
      if (subscribeResponse?.payload?.message === "Subscribed successfully!" && createAccountResponse?.payload?.message === "Account created successfully!") {
        dispatch(
          showToast({
            show: false,
            message: "Subscribed successfully!",
            type: "success",
          })
        );
        navigate("/dashboard");
      }
      else if (subscribeResponse?.payload?.message === "Your onboarding is already completed.") {
        dispatch(
          showToast({
            show: false,
            message: "Your onboarding is already completed.",
            type: "success",
          })
        );
        navigate("/dashboard");
      }
      else {
        dispatch(
          showToast({
            show: false,
            message: "Error in subscription",
            type: "error",
          })
        );
      }
      setBusy(false);
    }
  };

  const ProfileForm = {
    fullName: {
      name: "fullName",
      validate: {
        required: {
          value: true,
          message: t("PLEASE_ENTER_FULL_NAME"),
        },
      },
    },
    location: {
      name: "location",
      validate: {
        required: {
          value: true,
          message: t("PLEASE_ENTER_LOCATION"),
        },
      },
    },
    dropdown: {
      name: "dropdown",
      validate: {
        required: {
          value: true,
          message: t("PLEASE_SELECT_OPTION"),
        },
      },
    },
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (leagueOptionRef.current && !leagueOptionRef.current.contains(event.target as Node)) {
        setIsOpenLeague(false); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [leagueOptionRef?.current]);

  useEffect(() => {
    dispatch(getUserLeagueList()).then((res: any) => {
      if (getUserLeagueList.fulfilled.match(res)) {
        const optionData = res?.payload?.data?.map((item: any) => {
          const capitalizedLabel = item?.charAt(0).toUpperCase() + item.slice(1);
          return { label: capitalizedLabel, value: item };
        })
        setLeagueOption(optionData)
      }
    })
  }, [])

  const resetForm = {
		password: {
			name: "password",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_ENTER_PASSWORD"),
				},
				validate: {
					hasLowerCase_UpperCase: (value: string) =>
						value &&
						value.match(Constant.REGEX.LOWERCASE) &&
						value.match(Constant.REGEX.UPPERCASE) !== null,
					hasNumbers: (value: string) =>
						value && value.match(Constant.REGEX.NUMBER) !== null,
					hasSpecialChar: (value: string) =>
						value &&
						value.match(Constant.REGEX.SPECIAL_CHARECTERS) !== null,
					length: (value: string) =>
						(value && value.length >= 8 && value.length <= 16) ||
						"",
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
		confirmpassword: {
			name: "confirmPassword",
			validate: {
				required: {
					value: true,
					message: t("PLEASE_RE-ENTER_THE_PASSWORD"),
				},
				maxLength: {
					value: 256,
					message: t("MAXIMUM_CHARACTERS"),
				},
			},
		},
	};

  const multiErrorFields = [
		{ length: t("MINIMUM_8_CHARACTERS") },
		{ hasSpecialChar: t("MINIMUM_1_SPECIAL") },
		{ hasNumbers: t("MINIMUM_1_NUMERIC") },
		{ hasLowerCase_UpperCase: t("MINIMUM_1_UPPERCASE_LOWERCASE") },
	];
  if (loading) return null; 

  return (
    <>
      <Helmet>
        <title>Profile | Triptix</title>
        <meta name="description" content="Profile | Triptix" />
      </Helmet>
    <div className="profileContainer">
      <ProfileComponent
        ProfileForm={ProfileForm}
        onFormSubmit={onFormSubmit}
        busy={busy}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        fullName={fullName}
        setUploadProfile={setUploadProfile}
        isOpenLeague={isOpenLeague}
        leagueOptionRef={leagueOptionRef}
        setIsOpenLeague={setIsOpenLeague}
        leagueOption={leagueOption}
        signInForm={resetForm}
        multiErrorFields={multiErrorFields}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
        confirmShowPassword={confirmShowPassword}
        setConfirmShowPassword={setConfirmShowPassword}
        isImported={isImported}
      />
      </div>
    </>
  );
};

export default ProfileContainer;

